import React from "react";
function NavBrand () {
    return (
        <div className="nav-brand">
            <img 
            src="https://i.imgur.com/bvd8rka.png" 
            alt="Rodrigruez-lawgroup-brand-logo" />
        </div>
    )
  }

  export default NavBrand;